import React from 'react';
import { PageColor } from '../components/page-color';
import { Content } from '../components/content';
import { Gallery } from '../components/gallery';

export default class Cases extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  get person () {
    return this.props.pageContext.person;
  }

  render = () => (
    <main className="case team-detail">
      <PageColor color="navy" nav="pigeon" />
      <div className="case__left">
        <div className="case__left__image">
          { this.person.video
              ? <video autoPlay playsInline loop muted src={this.person.video.file.url} ref={_ => this.video = _ }/> : ''
          }
        </div>
      </div>
      <div className="case__right">
        <div className="case__content">
          <h1>
            <div className="title-poppins">{ this.person.firstname }</div>
            <div className="title-ivy">{ this.person.lastname }</div>
          </h1>

          <div className="team-detail__role title-montserrat">{ this.person.role }</div>

          <div className="team-detail__bio">{ this.person.quoteBiography }</div>

          <div className="team-detail__contact-title  title-montserrat">contact</div>
          <div className="team-detail__contact-content"><a href={'mailto:' + this.props.email}>{ this.person.email }</a></div>
        </div>
      </div>
    </main>
  );
}
